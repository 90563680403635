import { AddressWithSuggestion, DuplicatedAddressGroup, IAddressComplete, IAddressDetails } from 'modules/review/types/address-list.types'

export type UploadPlaceholderResponse = {
  requestId: string
  uploadUrl: string
}

export type UploadedAddressesResponse = {
  totalNumberOfAddresses: number
  totalNumberOfInvalidAddresses: number
  totalNumberOfDuplicatedAddresses: number
  totalNumberOfValidAddresses: number
  addressIds?: string[]
  requestId: string
  shopperId: string
  status: string
  processingTimeStamp: number
  createdTimeStamp: number
  originalFileName: string
  invalidDataTimeStamp?: number
  finishedTimeStamp?: number
  duplicatedAddressGroups?: DuplicatedAddressGroup[]
  invalidAddresses?: AddressWithSuggestion[]
  validAddresses?: IAddressDetails[]
  addressBookId?: string
}

export type UpdateAddressRequest = {
  addressesToUpdate: IAddressDetails[]
  addressIndexesToRemove: number[]
  duplicatedAddressIndexesToPromoteAsValid: number[]
}

export type UpdateSavedAddressesRequest = {
  addresses: IAddressComplete[]
}

export type CreateMailingListResponse = {
  addressIds: string[]
  mailingListId: string
  name: string
  shopperId: string
}

export enum RequestProcessingState {
  CREATED = 'created',
  PROCESSING = 'processing',
  FINISHED = 'finished',
  CANCELED = 'canceled',
  INVALID_DATA = 'invalidData',
}
