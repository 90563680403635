import { Column, GridContainer, Hidden, Row } from '@vp/swan'
import { FullPageLoader } from 'components/loader/loader.component'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { WorkIdPreview } from 'components/work-id-preview/work-id-preview.component'
import { useByPassCheck } from 'contexts/query-param.context'
import { useToastSomethingWentWrong } from 'lib/toast'
import { usePreview } from 'hooks/use-preview.hook'
import { PageLayout } from 'layouts/page/page.layout'
import { useAddressList } from 'lib/address-list'
import { useMsgSeoDescriptionReviewPage, useMsgSeoTitleReviewPage } from 'lib/intl/msg-seo-info.hooks'
import { PageName } from 'lib/telemetry'
import { PageNameForLogging } from 'lib/telemetry/tracking.types'
import { useWorkDetails } from 'lib/work-entity'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AddressFormatInfo } from '../components/address-format-info/address-format-info.component'
import { AddressTabs } from '../components/address-tabs/address-tabs.component'
import { BtnMailingListCreate } from '../components/btn-mailing-list-create/btn-mailing-list-create.component'
import { Strategize } from '../components/strategize/strategize.component'
import { ValidationHeader } from '../components/validation-header/validation-header.component'
import { useAddressPreview } from '../contexts/address-preview.context'
import { IAddressDetails } from '../types/address-list.types'
import { AddressType } from '../types/address-multi-selection.types'
import { useReviewTabId } from '../contexts/review-config.context'
import { getInitialValue, isManualFlow, isReentryFlow } from 'hooks/use-query-string-state.hook'
import { QUERY_KEY_MAILING_LIST_ID, QUERY_KEY_REQUEST_ID } from 'constants/query-key.constants'
import { SavedListFlowValidationHeader } from '../components/validation-header/saved-list-flow-validation-header.component'
import { TitleAlertBox } from 'components/title-alert-box/title-alert-box.component'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { RequestProcessingState } from 'types/upload-mailing-list.types'

export const ReviewPage: FC<unknown> = () => {
  const isSavedListFlow = getInitialValue(QUERY_KEY_MAILING_LIST_ID, '')
  const isReviewRequestFlow = getInitialValue(QUERY_KEY_REQUEST_ID, '')
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag, reEntryFeatureToggle, loadingreEntryFeatureFlag } = useFeatureFlagContext()
  const { data: addresses, isIdle, isLoading, isError, isSuccess } = useAddressList()
  const { data: workDetails } = useWorkDetails()
  const { actionConfig, onPreview } = useAddressPreview()
  const somethingWentWrong = useToastSomethingWentWrong()
  const {
    loading: mappingInProgress,
    mutationResult: { mutateAsync: mapAddressForPreview },
  } = usePreview()
  const seoTitleReviewPage = useMsgSeoTitleReviewPage()
  const seoDescriptionReviewPage = useMsgSeoDescriptionReviewPage()
  const [isPreviewLoading, setIsPreviewLoading] = useState(false)
  const [showSavedStatus, setSavedStatus] = useState(false)
  const [renderPreview, setRenderPreview] = useState(uuidv4())
  const tabElem = useRef<HTMLInputElement>(null)
  const inValidAddressesCount = useMemo(() => addresses?.totalNumberOfInvalidAddresses || 0, [addresses?.totalNumberOfInvalidAddresses])
  const duplicateAddressesCount = useMemo(() => addresses?.totalNumberOfDuplicatedAddresses || 0, [addresses?.totalNumberOfDuplicatedAddresses])
  const totalAddressCount = useMemo(() => addresses?.totalNumberOfAddresses || 0, [addresses?.totalNumberOfAddresses])
  const initialInvalidAddressesCount = useRef<number>()
  const initialDuplicateAddressCount = useRef<number>()
  useEffect(() => {
    if (!initialInvalidAddressesCount.current) {
      initialInvalidAddressesCount.current = addresses?.totalNumberOfInvalidAddresses
    }
    if (!initialDuplicateAddressCount.current) {
      initialDuplicateAddressCount.current = addresses?.totalNumberOfDuplicatedAddresses
    }
  }, [addresses?.totalNumberOfInvalidAddresses, addresses?.totalNumberOfDuplicatedAddresses])

  const byPassCheck = useByPassCheck()
  const [activeTabId] = useReviewTabId()

  const autoMove = () => {
    tabElem?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
  useEffect(() => {
    setSavedStatus(true)
    const timer = setTimeout(() => {
      setSavedStatus(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [addresses])

  useEffect(() => {
    if (addresses?.validAddresses && addresses?.totalNumberOfValidAddresses > 0 && activeTabId !== AddressType.DUPLICATE.toLowerCase()) {
      onPreview({
        address: { ...addresses?.validAddresses[0] },
        rowIndex: String(addresses?.validAddresses[0].rowIndex),
        addressType: AddressType.VALID,
      })
    } else if (addresses?.duplicatedAddressGroups && addresses?.totalNumberOfDuplicatedAddresses > 0 && activeTabId !== AddressType.VALID.toLowerCase()) {
      onPreview({
        address: { ...addresses?.duplicatedAddressGroups[0].addresses[0] },
        rowIndex: String(addresses?.duplicatedAddressGroups[0].addresses[0].rowIndex),
        addressType: AddressType.VALID,
      })
    }
  }, [addresses])

  const preview = useCallback(
    ({ address }: { address?: IAddressDetails }) => {
      setIsPreviewLoading(true)
      try {
        mapAddressForPreview(
          { address },
          {
            onSettled: () => {
              if (!mappingInProgress) {
                // This states that if we received the isPreviewReady output from use-preview.hook

                setRenderPreview(uuidv4()) // This will change the renderPreview values passed to workIdPreview component
              }
            },
          },
        )
      } catch (e) {
        somethingWentWrong()
      }
    },
    [mapAddressForPreview, somethingWentWrong],
  )

  useEffect(() => {
    if (actionConfig?.rowIndex) {
      preview({ address: actionConfig?.address })
    }
  }, [actionConfig?.address, actionConfig.rowIndex, preview])

  const isPageLoading = isLoading || !workDetails?.workId

  const onPreviewLoad = () => {
    setIsPreviewLoading(false)
  }

  const renderValidationSection = () => {
    if (isSavedListFlow && manualAddressingFeatureToggle && !loadingManualAddressingFlag) {
      return (
        <SavedListFlowValidationHeader
          addedAddressCount={addresses?.validAddresses?.length || 0}
          isError={isError}
          isLoading={isLoading}
          isIdle={isIdle}
          isSuccess={isSuccess}
        />
      )
    } else {
      return (
        <>
          <ValidationHeader
            valid={addresses?.totalNumberOfValidAddresses || 0}
            duplicate={duplicateAddressesCount || 0}
            invalid={inValidAddressesCount || 0}
            isError={isError}
            isLoading={isLoading}
            isIdle={isIdle}
            isSuccess={isSuccess}
            total={totalAddressCount}
            initialInvalids={initialInvalidAddressesCount.current || 0}
            initialDuplicates={initialDuplicateAddressCount.current || 0}
          />
          <Strategize onChange={autoMove} areAllValid={!inValidAddressesCount && !duplicateAddressesCount} isIdle={isIdle} />
          <div ref={tabElem}>
            <AddressFormatInfo />
          </div>
        </>
      )
    }
  }

  const isReEntryFlow = isReentryFlow()
  const isManual = isManualFlow()

  let trackingProductPageName = ''
  if (isManual) {
    trackingProductPageName = PageName.MANUAL_ADD_LIST
  } else if (isReEntryFlow) {
    trackingProductPageName = PageName.REENTRY_EDIT_LIST
  } else if (isSavedListFlow) {
    trackingProductPageName = PageName.SAVED_LIST_REVIEW_PAGE
  } else if (isReviewRequestFlow) {
    trackingProductPageName = PageName.REVIEW_PAGE
  }

  return (
    <>
      <PageSeoTitle content={seoTitleReviewPage} />
      <PageSeoDescription content={seoDescriptionReviewPage} />
      {isPageLoading && !byPassCheck && <FullPageLoader />}
      <PageLayout
        nextElem={
          <BtnMailingListCreate
            isAbleToCreateFromWork={(addresses?.totalNumberOfValidAddresses ?? 0) > 0 && addresses?.status === RequestProcessingState.FINISHED}
            mailingListName={addresses?.originalFileName}
          />
        }
        fileName={addresses?.originalFileName}
        pageName={trackingProductPageName}
        pageNameForLogging={PageNameForLogging.REVIEW_PAGE}
        onBackConfirm
        showSavedStatus={showSavedStatus}
      >
        <Row>
          <Column span={12}>{isReEntryFlow && reEntryFeatureToggle && !loadingreEntryFeatureFlag && <TitleAlertBox />}</Column>
        </Row>
        <GridContainer pt={isReEntryFlow && reEntryFeatureToggle && !loadingreEntryFeatureFlag ? { xs: 7, sm: 8, md: 8 } : { xs: 8, sm: 9, md: 10 }}>
          <Row sticky mb={6}>
            <Column spanXs={12} spanSm={12} spanMd={6} spanLg={6} span={6}>
              {!loadingManualAddressingFlag && renderValidationSection()}
              <AddressTabs isIdle={isIdle} isLoading={isLoading} isPreviewLoading={isPreviewLoading} />
            </Column>
            <Column sticky span={5} spanMd={6} offset={1} offsetMd={0} style={{ top: '100px' }}>
              <Hidden xs sm>
                <div>
                  <WorkIdPreview purpose="design.studio.review" key={renderPreview} isPreviewLoading={isPreviewLoading} onPreviewLoad={onPreviewLoad} />
                </div>
              </Hidden>
            </Column>
          </Row>
        </GridContainer>
      </PageLayout>
    </>
  )
}
