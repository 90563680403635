import { FlexBox, H3, Icon, Typography } from '@vp/swan'
import { TabularShimmer } from 'components/loader/shimmer/shimmer.component'
import { useRequestIdQueryParam } from 'contexts/query-param.context'
import { Error } from 'lib/errors'
import { useMsgErrorWhileFetching } from 'lib/intl/msg-errors.hooks'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useProductInfoQtySelection } from 'lib/product-info'
import { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import classes from './validation-header-saved-list-flow.module.scss'
import { useMsgLessRecipientList, useListGoodToGoMsg } from 'lib/intl/msg-address-format-info.hooks'
import { useNeedMoreEnvelopesMsg } from 'lib/intl/msg-validation-header.hooks'

type SavedListFlowValidationHeaderProps = {
  addedAddressCount: number
  isLoading: boolean
  isError: boolean
  isIdle: boolean
  isSuccess: boolean
}

export const SavedListFlowValidationHeader: FC<SavedListFlowValidationHeaderProps> = ({ addedAddressCount, isLoading, isError, isIdle, isSuccess }) => {
  const errorWhileFetching = useMsgErrorWhileFetching()
  const requestId = useRequestIdQueryParam()
  const { selectedQty: addedEnvelopesCount } = useProductInfoQtySelection()
  const lessAddressListMsg = useMsgLessRecipientList(addedEnvelopesCount)
  const listGoodToGoMsg = useListGoodToGoMsg()
  const excessAddressesLabel = useNeedMoreEnvelopesMsg(addedAddressCount, addedEnvelopesCount)
  const { logError } = useLogger()

  useEffect(() => {
    if (isError) {
      logError('Error occurred while fetching addresses', { contextData: { requestId } })
    }
  }, [isError, requestId, logError])

  return (
    <section>
      {isError && <Error message={errorWhileFetching} align="left" />}
      {(isIdle || isLoading) && <TabularShimmer className={classes.shimmerContainer} mt={5} rows={1} cols={3} />}
      <H3 fontSize={'x2large'} mb={{ md: 5, xs: 4 }} fontWeight="bold">
        {addedEnvelopesCount === addedAddressCount ? (
          listGoodToGoMsg
        ) : (
          <FormattedMessage defaultMessage="Add recipients' addresses." description="Heading for saved list flow to add recipients" />
        )}
      </H3>
      {!isError && !isLoading && isSuccess && (
        <FlexBox flexDirection="column" py={2} mb={7}>
          <FlexBox alignItems="center">
            <Icon iconType="success" size="20p" skin="success" />
            <Typography fontSize={'standard'} textAlign="left" pl={3}>
              <FormattedMessage defaultMessage="{count} Envelopes added" description="Count of envelopes added" values={{ count: addedEnvelopesCount }} />
            </Typography>
          </FlexBox>

          {addedAddressCount > 0 && (
            <FlexBox alignItems="center">
              <Icon iconType="success" size="20p" skin="success" />
              <Typography fontSize={'standard'} textAlign="left" pl={3}>
                <FormattedMessage defaultMessage="{count} Recipients added" description="Count of recipients added" values={{ count: addedAddressCount }} />
              </Typography>
            </FlexBox>
          )}

          <FlexBox mt={{ md: 5, xs: 4 }}>
            {addedAddressCount > addedEnvelopesCount ? <Icon iconType="error" size="20p" skin="error" /> : ''}
            <Typography fontSize={'standard'} textAlign="left" pl={addedAddressCount > addedEnvelopesCount ? '3' : '0'}>
              {addedEnvelopesCount === addedAddressCount ? (
                <FormattedMessage defaultMessage="You've addressed all envelopes." description="Envelopes and recipients count match" />
              ) : addedAddressCount > addedEnvelopesCount ? (
                excessAddressesLabel
              ) : (
                lessAddressListMsg
              )}
            </Typography>
          </FlexBox>
        </FlexBox>
      )}
    </section>
  )
}
